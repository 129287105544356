<template>
  <div :class="{'active': showISI}" class="pi-content">
    <pi-component @closeISI="toggleISI"></pi-component>
  </div>
  <div class="important-information">
    <div class="information">
      <p>Please click link below for Prescribing Information, including Boxed Warning.</p>
      <a target="_blank" href="https://www.novo-pi.com/wegovy.pdf">Wegovy<sup>&reg;</sup> Prescribing Information</a>
      <a @click="toggleISI">Wegovy<sup>&reg;</sup> Important Safety Information</a>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { computed } from 'vue';
import PiComponent from '@/components/game/PiComponent.vue';

export default {
  name: 'FooterComponent',
  components: {
    PiComponent
  },
  setup() {
    const store = useStore();
    const showISI = computed(() => store.getters.getShowISI);
    const toggleISI = () => {
      store.commit('toggleISI');
    };

    return { showISI, toggleISI };
  }
}
</script>

<style scoped lang="scss">
.pi-content {width:90%;position: absolute;left:50%;transform: translate(-50%,100%);opacity:0;box-sizing: border-box;overflow-y: auto;color:#001965;transition: opacity 0.5s, transform 0.5s;font-size: 12px;
  &.active {opacity:1;transform: translate(-50%,0);}
}
.important-information {position: absolute;bottom:0;left: 0;width: 100%;background: rgba(#FFFFFF,0.86);display: flex;align-items: center;justify-content: center;
  .information {max-width:750px;text-align: center;
    p {color:#555555;font-weight:500;margin:0;width:100%;}
    a, button {cursor:pointer;padding:0;font-weight:500;box-sizing:border-box;display:block;height:auto;border:1px solid #fff;background: linear-gradient(#FFFFFF, #DAE0DE);text-align:center;text-decoration:none;color:#555555;
      &:hover, &:focus {background: linear-gradient(#DAE0DE, #DAE0DE);color:#000;}
    }
  }
}
@media screen and (max-aspect-ratio: 1/1) {
  .pi-content {bottom:min(16vw,120px);height:calc(100% - min(34vw,260px));height:calc(100dvh - min(34vw,260px));border-radius: min(2vw,15px) min(2vw,15px) 0 0;}
  .important-information {height:min(16vw,120px);
    .information {
      p {font-size: min(2.667vw,20px);padding-bottom: min(1vw,7.5px);}
      a, button {width:calc(50% - min(1vw,7.5px));font-size: min(2.3vw,18px);line-height:min(6vw,45px);}
      a {float:left;}
      button {float:right;}
    }
  }
}
@media screen and (min-aspect-ratio: 1/1) {
  .pi-content {bottom:min(16vh,120px);height:calc(100vh - min(34vh,260px));height:calc(100dvh - min(34vh,260px));border-radius: min(2vh,15px) min(2vh,15px) 0 0;max-width:1200px;}
  .important-information {height:min(16vh,120px);
    .information {
      p {font-size: min(2.667vh,20px);padding-bottom: min(1vh,7.5px);}
      a, button {width:calc(50% - min(1vw,7.5px));font-size: min(2.1vh,18px);line-height:min(6vh,45px);}
      a {float:left;}
      button {float:right;}
    }
  }
}
</style>
