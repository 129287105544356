<template>
    <CompatibilityCheck v-if="!isCompatible" />
    <PerformanceCheck v-if="isCompatible && checkPerformance" />
    <EnvironmentComponent @codeValidation="handleValidation" v-if="gameReady" />
    <DebugBox v-if="debug" />
</template>

<script>
import { mapState } from 'vuex';
import CompatibilityCheck from './components/validation/CompatibilityCheck.vue';
import PerformanceCheck from './components/validation/PerformanceCheck.vue';
import EnvironmentComponent from './components/EnvironmentComponent.vue';
import DebugBox from './components/utils/DebugBox.vue';
import {ref} from "vue";

export default {
  name: 'App',
  components: {
    CompatibilityCheck,
    PerformanceCheck,
    EnvironmentComponent,
    DebugBox
  },
  computed: {
    ...mapState(['isCompatible', 'checkCompatibility', 'checkPerformance', 'gameReady'])
  },
  setup: () => {
    //if ?debug=true in URL, set debug to true
    const debug = ref(false);
    if (window.location.search.includes('debug=true')) {
      debug.value = true;
    }

    return {debug};
  }
}
</script>
<style lang="scss">
//@font-face {  font-family: 'ApisWeb';  src: url('@/fonts/eot-tt/ApisWeb-Light.eot');  src: url('@/fonts/eot-tt/ApisWeb-Light.eot?#iefix') format('embedded-opentype'), url('@/fonts/woff-woff2-tt/ApisWeb-Light.woff2') format('woff2'), url('@/fonts/woff-tt/ApisWeb-Light.woff') format('woff');  font-weight: 300;  font-style: normal;  }
//@font-face {  font-family: 'ApisWeb';  src: url('@/fonts/eot-tt/ApisWeb-LightItalic.eot');  src: url('@/fonts/eot-tt/ApisWeb-LightItalic.eot?#iefix') format('embedded-opentype'), url('@/fonts/woff-woff2-tt/ApisWeb-LightItalic.woff2') format('woff2'), url('@/fonts/woff-tt/ApisWeb-LightItalic.woff') format('woff');  font-weight: 300;  font-style: italic;  }
@font-face {  font-family: 'ApisWeb';  src: url('@/fonts/eot-tt/ApisWeb-Regular.eot');  src: url('@/fonts/eot-tt/ApisWeb-Regular.eot?#iefix') format('embedded-opentype'), url('@/fonts/woff-woff2-tt/ApisWeb-Regular.woff2') format('woff2'), url('@/fonts/woff-tt/ApisWeb-Regular.woff') format('woff');  font-weight: 400;  font-style: normal;  }
@font-face {  font-family: 'ApisWeb';  src: url('@/fonts/eot-tt/ApisWeb-Italic.eot');  src: url('@/fonts/eot-tt/ApisWeb-Italic.eot?#iefix') format('embedded-opentype'), url('@/fonts/woff-woff2-tt/ApisWeb-Italic.woff2') format('woff2'), url('@/fonts/woff-tt/ApisWeb-Italic.woff') format('woff');  font-weight: 400;  font-style: italic;  }
@font-face {  font-family: 'ApisWeb';  src: url('@/fonts/eot-tt/ApisWeb-Medium.eot');  src: url('@/fonts/eot-tt/ApisWeb-Medium.eot?#iefix') format('embedded-opentype'), url('@/fonts/woff-woff2-tt/ApisWeb-Medium.woff2') format('woff2'), url('@/fonts/woff-tt/ApisWeb-Medium.woff') format('woff');  font-weight: 500;  font-style: normal;  }
@font-face {  font-family: 'ApisWeb';  src: url('@/fonts/eot-tt/ApisWeb-MediumItalic.eot');  src: url('@/fonts/eot-tt/ApisWeb-MediumItalic.eot?#iefix') format('embedded-opentype'), url('@/fonts/woff-woff2-tt/ApisWeb-MediumItalic.woff2') format('woff2'), url('@/fonts/woff-tt/ApisWeb-MediumItalic.woff') format('woff');  font-weight: 500;  font-style: italic;  }
@font-face {  font-family: 'ApisWeb';  src: url('@/fonts/eot-tt/ApisWeb-Bold.eot');  src: url('@/fonts/eot-tt/ApisWeb-Bold.eot?#iefix') format('embedded-opentype'), url('@/fonts/woff-woff2-tt/ApisWeb-Bold.woff2') format('woff2'), url('@/fonts/woff-tt/ApisWeb-Bold.woff') format('woff');  font-weight: 700;  font-style: normal;  }
//@font-face {  font-family: 'ApisWeb';  src: url('@/fonts/eot-tt/ApisWeb-ExtraBold.eot');  src: url('@/fonts/eot-tt/ApisWeb-ExtraBold.eot?#iefix') format('embedded-opentype'), url('@/fonts/woff-woff2-tt/ApisWeb-ExtraBold.woff2') format('woff2'), url('@/fonts/woff-tt/ApisWeb-ExtraBold.woff') format('woff');  font-weight: 800;  font-style: normal;  }
//@font-face {  font-family: 'ApisWeb';  src: url('@/fonts/eot-tt/ApisWeb-ExtraBoldItalic.eot');  src: url('@/fonts/eot-tt/ApisWeb-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'), url('@/fonts/woff-woff2-tt/ApisWeb-ExtraBoldItalic.woff2') format('woff2'), url('@/fonts/woff-tt/ApisWeb-ExtraBoldItalic.woff') format('woff');  font-weight: 800;  font-style: italic;  }
//@font-face {  font-family: 'ApisWeb';  src: url('@/fonts/eot-tt/ApisWeb-Black.eot');  src: url('@/fonts/eot-tt/ApisWeb-Black.eot?#iefix') format('embedded-opentype'), url('@/fonts/woff-woff2-tt/ApisWeb-Black.woff2') format('woff2'), url('@/fonts/woff-tt/ApisWeb-Black.woff') format('woff');  font-weight: 900;  font-style: normal;  }
//@font-face {  font-family: 'ApisWeb';  src: url('@/fonts/eot-tt/ApisWeb-BlackItalic.eot');  src: url('@/fonts/eot-tt/ApisWeb-BlackItalic.eot?#iefix') format('embedded-opentype'), url('@/fonts/woff-woff2-tt/ApisWeb-BlackItalic.woff2') format('woff2'), url('@/fonts/woff-tt/ApisWeb-BlackItalic.woff') format('woff');  font-weight: 900;  font-style: italic;  }
html, body {width:100vw;position:absolute;top:0;left:0;background: #D4E5EF radial-gradient(circle, #fff 0%, #D4E5EF 70%, #D4E5EF 100%);font-family: 'ApisWeb', sans-serif;height: 100%;height: 100dvh;margin: 0;padding: 0;font-weight: 400;-webkit-text-size-adjust: none;-moz-text-size-adjust: none;-ms-text-size-adjust: none;text-size-adjust: none;overflow: hidden;
  small{font-size: 18px;margin-bottom:20px;display:inline-block;}
  .card .body .card-content .centered-content .question small{color:#0092BC;}
  #app {height: 100%;
    sup {font-size:60%;}
    ol{padding-left: 30px;}
    ul{padding-left:30px;
      li{
        ul{ list-style-type: none;position: relative;
          li:before {
            content: "-";
            position: absolute;
            left:10px;
          }
        }
      }
    }
  }
}

</style>