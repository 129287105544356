<template>
  <div class="gameComponent">
    <welcome-component v-if="!getWelcomeComplete" @startGame="startGame"></welcome-component>
    <gameboard-component :soundUtility="soundUtility" :placesData="placesData" @rollDone="endTurn" @endGame="endTheGame"></gameboard-component>
    <dice-component :totalSpaces="placesData.length" :clickHere="clickHere" :soundUtility="soundUtility" @diceRolled="handleDiceRoll" @roll="rollDice" @startSoundIfNotStarted="startSoundIfNotStarted"></dice-component>
    <progress-component></progress-component>
    <cards-component ref="cardComponentRef" :soundUtility="soundUtility" @hideCards="hideCards"></cards-component>
    <high-score-component></high-score-component>
    <logo-component></logo-component>
    <end-game-component></end-game-component>
    <footer-component></footer-component>
    <button v-show="musicStarted" id="masterSound" @click="toggleMasterVolume">
      <font-awesome-icon :icon="isMuted ? 'volume-mute' : 'volume-up'" />
    </button>
  </div>
</template>

<script>
import { ref, onMounted, toRaw } from 'vue';
import { mapGetters, useStore } from 'vuex';
import SoundUtility from '@/components/utils/soundUtility';
import GameboardComponent from '@/components/game/GameboardComponent.vue';
import WelcomeComponent from "@/components/game/WelcomeComponent.vue";
import EndGameComponent from '@/components/game/EndGameComponent.vue';
import FooterComponent from '@/components/game/FooterComponent.vue';
import HighScoreComponent from "@/components/game/HighScoreComponent.vue";
import DiceComponent from '@/components/game/DiceComponent.vue';
import placesData from '@/assets/places.json';
import ProgressComponent from "@/components/game/ProgressComponent.vue";
import CardsComponent from "@/components/game/CardsComponent.vue";
import LogoComponent from "@/components/game/LogoComponent.vue";
export default {
  name: 'GameComponent',
  components: {
    CardsComponent,
    ProgressComponent,
    GameboardComponent,
    DiceComponent,
    EndGameComponent,
    WelcomeComponent,
    FooterComponent,
    HighScoreComponent,
    LogoComponent
  },
  computed: {
    ...mapGetters(['getWelcomeComplete', 'getMasterVolumeState', 'getCurrentScore', 'getCurrentSpace', 'getIsAnimating', 'getRoll', 'getGameEnded', 'getCurrentSpaceType'])
  },
  setup() {
    const soundUtility = ref(null);
    const store = useStore();
    const musicStarted = ref(false);
    const isMuted = ref(store.getters.getMasterVolumeState);
    const cardComponentRef = ref(null);
    const clickHere = ref(false);
    const addPoints = (points) => {
      store.dispatch('setCardsShowing', false);
      if(store.getters.getGameType === 'Random 1' || store.getters.getGameType === 'Random 2'){
        //loop through questions and add up points and get percentage based on number of questions
        let savedQuestions = toRaw(store.getters.getSavedQuestions);
        let totalPoints = 0;
        let totalQuestions = 0;
        for (const questions of Object.keys(savedQuestions)) {
          totalPoints += savedQuestions[questions].points;
          totalQuestions++;
        }
        if(totalPoints>0){
          let aggregatePoints = Math.round(totalPoints / totalQuestions);
          store.dispatch('setCurrentScore', aggregatePoints);
        }else{
          store.dispatch('setCurrentScore', 0);
        }
      }else{
        let currentScore = store.getters.getCurrentScore;
        let newScore = currentScore + points;
        let totalUpdates = 100;
        let remainingPoints = points;
        let addPoints = points / totalUpdates;
        let startTime = new Date().getTime(); // Record the start time
        soundUtility.value.playSound('points', 0.1, false);
        let pointsInterval = setInterval(() => {
          let elapsed = new Date().getTime() - startTime;
          let remainingUpdates = totalUpdates - Math.floor(elapsed / 10);
          if (remainingUpdates <= 0 || remainingPoints <= 0) {
            store.dispatch('setCurrentScore', newScore);
            clearInterval(pointsInterval);
            return;
          }
          store.dispatch('addToCurrentScore', addPoints);
        }, 10);
      }
    };

    const hideCards = (points) => {
      store.dispatch('setCardsShowing', false);
      addPoints(points);
      setTimeout(() => {
        if(store.getters.getGameType !== 'Random 1' && store.getters.getGameType !== 'Random 2'){
          if(store.getters.getCurrentSpaceType==='questions.true-false'){
            if(store.getters.getGameType === 8 && store.getters.getTrueFalse < 1 || store.getters.getGameType === 12 && store.getters.getTrueFalse < 2){
              store.dispatch('setIncrementTrueFalse');
            }
          }
          if(store.getters.getCurrentSpaceType==='questions.multiple-choice'){
            if(store.getters.getGameType === 8 && store.getters.getMultipleChoice < 1 || store.getters.getGameType === 12 && store.getters.getMultipleChoice < 2){
              store.dispatch('setIncrementMultipleChoice');
            }
          }
          if(store.getters.getCurrentSpaceType==='questions.fill-in-blank'){
            if(store.getters.getGameType === 8 && store.getters.getFillInBlank < 1 || store.getters.getGameType === 12 && store.getters.getFillInBlank < 2){
              store.dispatch('setIncrementFillInBlank');
            }
          }
          if(store.getters.getCurrentSpaceType==='questions.unscramble'){
            if(store.getters.getGameType === 8 && store.getters.getUnscramble < 1 || store.getters.getGameType === 12 && store.getters.getUnscramble < 2){
              store.dispatch('setIncrementUnscramble');
            }
          }
        }
        setTimeout(() => {
          if(!store.getters.getIsAnimating && !store.getters.getCardsShowing){
            clickHere.value = true;
          }
        }, 2000);
      }, 1000);

    };

    const showCards = () => {
      store.dispatch('setCardsShowing', true);
      soundUtility.value.playSound('cardFlip', 0.2, false);
      setTimeout(() => {
        cardComponentRef.value.resizeCards();
      }, 1);
    };

    const toggleMasterVolume = () => {
      soundUtility.value.toggleMute();
      isMuted.value = soundUtility.value.isMuted;
    };

    const rollDice = () => {
      if (!store.getters.getIsAnimating && store.getters.getCurrentSpace < placesData.length) {
        clickHere.value = false;
        store.dispatch('setAnimating', true);
      }
    };

    const handleDiceRoll = (newSpace) => {
      store.dispatch('setAnimatingToSpace', newSpace);
    };

    const endTurn = (newCurrentSpace, duration) => {
      store.dispatch('setCurrentSpace', newCurrentSpace);
      store.dispatch('setCurrentSpaceType', placesData[newCurrentSpace].type);
      if(store.getters.getGameType === 'Random 1' || store.getters.getGameType === 'Random 2'){
        if(placesData[newCurrentSpace].type === 'questions.true-false'){
          store.dispatch('setTrueFalse', placesData[newCurrentSpace].question);
        }
        if(placesData[newCurrentSpace].type === 'questions.multiple-choice'){
          store.dispatch('setMultipleChoice', placesData[newCurrentSpace].question);
        }
        if(placesData[newCurrentSpace].type === 'questions.fill-in-blank'){
          store.dispatch('setFillInBlank', placesData[newCurrentSpace].question);
        }
        if(placesData[newCurrentSpace].type === 'questions.unscramble'){
          store.dispatch('setUnscramble', placesData[newCurrentSpace].question);
        }
      }
      store.dispatch('setAnimating', false);
      store.dispatch('setAnimatingToSpace', false);
      store.dispatch('setProgress', calculateProgress(newCurrentSpace));
      if (duration && shouldShowCards()) {
        store.dispatch('setRoll', store.getters.getRoll + 1);
        showCards();
      }
    };

    const calculateProgress = (currentSpace) => {
      return Math.floor((currentSpace / (placesData.length - 1)) * 100);
    };

    const shouldShowCards = () => {
      return !store.getters.getIsAnimating &&
          !store.getters.getGameEnded &&
          store.getters.getCurrentSpaceType !== null &&
          store.getters.getCurrentSpaceType !== "Starting Point";
    };

    const endTheGame = () => {
      const lastSpace = placesData.length - 1;
      store.dispatch('setCurrentSpace', lastSpace);
      store.dispatch('setCurrentSpaceType', placesData[lastSpace].type);
      store.dispatch('setAnimating', false);
      store.dispatch('setAnimatingToSpace', false);
      store.dispatch('setGameEnded', true);
      store.dispatch('setProgress', 100);
      soundUtility.value.playSound('congrats', 0.5, false);
    };

    const loadSounds = async () => {
      await soundUtility.value.loadSound('backgroundMusic', '/sounds/wegovy-game-board_background-music-loop.mp3');
      await soundUtility.value.loadSound('diceRoll', '/sounds/dice.mp3');
      await soundUtility.value.loadSound('cardFlip', '/sounds/card-flip.mp3');
      await soundUtility.value.loadSound('footSteps', '/sounds/footsteps.mp3');
      await soundUtility.value.loadSound('correct', '/sounds/correct.mp3');
      await soundUtility.value.loadSound('incorrect', '/sounds/incorrect-timeout.mp3');
      await soundUtility.value.loadSound('points', '/sounds/points-accruing.mp3');
      await soundUtility.value.loadSound('congrats', '/sounds/congrats.mp3');
    };

    const playBackgroundMusic = () => {
      soundUtility.value.playSound('backgroundMusic', 0.2, true);
    };

    const startSoundIfNotStarted = () => {
      if (!musicStarted.value) {
        if (soundUtility.value.audioContext.state === 'suspended') {
          soundUtility.value.audioContext.resume().then(playBackgroundMusic);
        } else {
          playBackgroundMusic();
        }
        musicStarted.value = true;
      }
    };

    const startGame = () => {
      store.dispatch('setGameStarted', true);
      setTimeout(() => {
        if(!store.getters.getIsAnimating && !store.getters.getCardsShowing){
          clickHere.value = true;
        }
      }, 3000);
      startSoundIfNotStarted();
    };

    const restartGame = () => {
      store.dispatch('resetGameData');
      window.location.reload(); // Optionally refresh the page
    };

    onMounted(() => {
      soundUtility.value = new SoundUtility(store);
      loadSounds();
    });

    return {
      store,
      startSoundIfNotStarted,
      soundUtility,
      toggleMasterVolume,
      startGame,
      isMuted,
      musicStarted,
      endTurn,
      endTheGame,
      hideCards,
      showCards,
      cardComponentRef,
      placesData,
      rollDice,
      handleDiceRoll,
      restartGame,
      clickHere
    };
  }
};
</script>

<style lang="scss" scoped>
.gameComponent {height: 100%;height: 100dvh;width: 100vw;position: absolute;top: 0;left: 0;overflow: hidden;
  #masterSound {-webkit-appearance: none;padding: 0;border: none;background: #fff;border-radius: 50%;max-height: 45px;max-width: 45px;cursor: pointer;position: absolute;z-index: 1000;color: #4D4D4D;
    @media screen and (max-aspect-ratio: 1/1) {width: 6vw;height: 6vw;font-size: min(3vw, 18px);top: min(2vw, 15px);right: min(29vw, 220px);}
    @media screen and (min-aspect-ratio: 1/1) {width: 6vh;height: 6vh;font-size: min(3vh, 18px);top: min(2vh, 15px);right: min(29vh, 220px);}
  }
}
</style>