<template>
  <div v-if="questionData">
    <div class="fill-in-blank card">
      <div class="header"><h2>Fill-in-the-Blank</h2></div>
      <div class="body">
        <div v-show="!questionData.showError && !questionData.showCorrect && !questionData.showReview" class="card-content">
          <div class="centered-content">
            <div class="question">
              <span class="section" v-for="(section, index) in activityContent.Sections" :key="section.id">
                <span v-html="section.Content"></span>
                <select :class="{'error':questionData.inError.includes(questionData.answers[index])}" v-model="questionData.answers[index]" :disabled="questionData.inCorrect.includes(questionData.answers[index])" @change="setAnswer(index)">
                  <option v-if="index===0" value="" disabled selected>------</option>
                  <option v-for="option in section.Options" :key="option.Title" :value="option.Title">{{option.Title}}</option>
                </select>
              </span>
              {{activityContent.ClosingContent}}
            </div>
          </div>
          <div class="note" v-html="activityContent.Note"></div>
        </div>
        <CorrectComponent  v-show="questionData.showCorrect"></CorrectComponent>
        <ErrorComponent v-show="questionData.showError" :questionData="questionData" :activityContent="activityContent" :answers="getCorrectAnswers()" :answer="''"></ErrorComponent>
        <ReviewComponent v-if="questionData.showReview" :questionData="questionData" :activityContent="activityContent" @seenReview="seenReview"></ReviewComponent>
      </div>
    </div>
    <TimerComponent ref="activityTimer" v-if="getCardsShowing" @timeout="handleTimeout" />
    <div class="points" :class="{'active':questionData.points !== null}">
      <div class="content">
        <span class="p">{{formatNumber(questionData.points,gameType)}}</span>
        <span class="text">Points</span>
      </div>
    </div>
    <div class="buttons">
      <button v-if="!questionData.showError && !questionData.showCorrect && !questionData.showReview" :disabled="(questionData.answers.length < activityContent.Sections.length || questionData.hasError)" class="card-button" @click="submitAnswer">Submit Final Answer <font-awesome-icon :icon="'angle-right'" /></button>
      <button v-if="!questionData.timedOut && questionData.showError && (questionData.errors < activityContent.NumAttempts || activityContent.NumAttempts === 0)" class="card-button" @click="tryAgain">Try Again <font-awesome-icon :icon="'angle-right'" /></button>
      <button v-if="(questionData.timedOut && questionData.showError) || questionData.showCorrect || questionData.showError && (questionData.errors >= activityContent.NumAttempts && activityContent.NumAttempts !== 0)" class="card-button" @click="enableReview">Review <font-awesome-icon :icon="'angle-right'" /></button>
      <button v-if="questionData.showReview" class="card-button" :disabled="!questionData.seenReview" @click="returnToGameBoard">Back to Gameboard  <font-awesome-icon :icon="'angle-right'" /></button>
    </div>
  </div>
  <div v-else>
    <div class="fill-in-blank card">{{questionData}}</div>
  </div>
</template>
<script>
import TimerComponent from './TimerComponent.vue';
import { CardMixin } from './CardMixin';
import {mapGetters} from "vuex";
import {ref} from "vue";
import CorrectComponent from "@/components/game/CardComponents/CorrectComponent.vue";
import ErrorComponent from "@/components/game/CardComponents/ErrorComponent.vue";
import ReviewComponent from "@/components/game/CardComponents/ReviewComponent.vue";
export default {
  name: 'FillInBlankComponent',
  components: {
    ErrorComponent,
    CorrectComponent,
    TimerComponent,
    ReviewComponent
  },
  computed: {
    ...mapGetters(['getCardsShowing']),
  },
  mixins: [CardMixin],
  emits: ['hideCards','soundCorrect', 'soundIncorrect'],
  setup(props, { emit }) {
    const { questionData,updateQuestionDataInStore, calculatePoints, createSaveData, gameType } = CardMixin.setup(props);
    const activityTimer = ref(null);
    const getCorrectAnswers = () => {
      let correctAnswers = props.activityContent.Sections.map(section => {
        return section.Options.find(option => option.IsCorrect === true).Title;
      });
      let answerCount = correctAnswers.length;
      let answerString;
      if(answerCount === 1){
        answerString = correctAnswers[0];
      } else {
        answerString = correctAnswers.join(', ');
      }
      return {num:answerCount, string:answerString};
    };
    const setAnswer = (key) => {
      let oldAnswer;
      if(questionData.value.lastAnswers[key]){
        oldAnswer = questionData.value.lastAnswers[key];
      }
      questionData.value.interacted = true;
      //push id and answer to the answers array
      questionData.value.lastAnswers[key] = event.target.value;
      if(oldAnswer){
        let index = questionData.value.inError.indexOf(oldAnswer);
        if(index > -1){
          questionData.value.inError.splice(index, 1);
        }
      }
      if(questionData.value.inError.length === 0){
        questionData.value.hasError = false;
      }
      updateQuestionDataInStore(questionData.value);
    };
    const formatNumber = (value,gameType)=> {
      let formattedNumber = new Intl.NumberFormat('en-US').format(value);
      if(gameType ==='Random 1' || gameType ==='Random 2'){
        formattedNumber+='%';
      }
      return formattedNumber;
    };
    const submitAnswer = () => {
      let correctAnswers = props.activityContent.Sections.map(section => {
        return section.Options.find(option => option.IsCorrect === true).Title;
      });
      //compare the correct answers to the answers in the questionData
      let allCorrect = true;
      //loop through the answers and compare them to the correct answers
      questionData.value.answers.forEach((answer, index) => {
        if (answer === correctAnswers[index]) {
          if(!questionData.value.inCorrect.includes(answer)){
            questionData.value.inCorrect.push(answer);
          }
        }else{
          allCorrect = false;
          if(!questionData.value.inError.includes(answer)) {
            questionData.value.inError.push(answer);
          }
        }
      });
      if(allCorrect){
        emit('soundCorrect');
        questionData.value.correct = true;
        questionData.value.showCorrect = true;
        questionData.value.correct = true;
      } else {
        emit('soundIncorrect');
        questionData.value.hasError = true;
        questionData.value.errors++;
        questionData.value.showError = true;
      }
      if(questionData.value.correct || (questionData.value.errors >= props.activityContent.NumAttempts &&  props.activityContent.NumAttempts !== 0)){
        if(!questionData.value.endTime){
          activityTimer.value.terminatenateWorker();
          questionData.value.endTime = Date.now();
          calculatePoints();
        }
      }
      updateQuestionDataInStore(questionData.value);
      createSaveData();
    };

    const returnToGameBoard = () => {
      emit('hideCards', questionData.value.points);
    };
    const handleTimeout = () => {
      questionData.value.timedOut = true;
      questionData.value.endTime = Date.now();
      calculatePoints();
      questionData.value.showError = true;
      updateQuestionDataInStore(questionData.value);
      createSaveData();
      emit('soundIncorrect');
    };

    const seenReview = () => {
      questionData.value.seenReview = true;
      updateQuestionDataInStore(questionData.value);
    };

    const tryAgain = () => {
      questionData.value.showError = false;
      questionData.value.answer = '';
      updateQuestionDataInStore(questionData.value);
    };

    const enableReview = async () => {
      questionData.value.showReview = true;
      questionData.value.showError = false;
      questionData.value.showCorrect = false;
      updateQuestionDataInStore(questionData.value);
    };

    return {
      returnToGameBoard,
      questionData,
      submitAnswer,
      handleTimeout,
      setAnswer,
      enableReview,
      tryAgain,
      activityTimer,
      seenReview,
      formatNumber,
      getCorrectAnswers,
      gameType
    };
  }
};
</script>

<style scoped lang="scss">
.points {width: 150px; height: 150px; position: absolute; left: 50%; top: 30px; transform: translate(-50%, -100%) scale(0); background: url(@/images/pointsBG.svg);background-size:contain; border-radius: 50%; display: flex; align-items: center; justify-content: center;color: #4d4d4d;opacity:0;transition: opacity 1s, transform 1.5s;
  .content{text-align: center;padding-top:25px;
    .p {font-size: 44px;font-weight: 700;display: block;width:100%;line-height:50px;}
    .text {font-size: 25px;font-weight: 700;display: block;width:100%;line-height:25px;}
  }
  &.active {transform: translate(-50%, -100%) scale(1);opacity:1;}
}
.card {width: 720px;height: 900px;background: #fff;position: absolute;top: 0;left: 50%;transform:translateX(-50%);border-radius: 11px;overflow: hidden;
  .header {background-color: #C6007E;width: 100%;height: 120px;position: relative;display: flex;align-items: center;justify-content: center;
    h2 {font-size: 40px;font-weight: 700;text-align: center;position: relative;z-index: 1;color: #fff;}
    &:after {content: '';position: absolute;top: 0;left: 0;width: 100%;height: 100%;background-image: url(@/images/cardHeader.svg);opacity: 0.19;}
  }
  .body {box-sizing: border-box;width: 100%;height: 780px;padding: 30px;overflow: auto;display: flex;align-items: center;
    .card-content{position: relative;height:100%;display: flex;width: 100%;
      .centered-content{width: 100%;
        .question{font-size: 25px;font-weight:700;color:#4D4D4D;line-height:80px;padding-top: 50px;
          .section {display: inline;position: relative;
            select {appearance: none;font-size: 25px;font-weight: 700;color: #4D4D4D;background:#fff;border:none;border-bottom: 2px solid #4D4D4D;border-radius:0;padding: 5px;margin: 0 5px;width: 330px;background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
              background-repeat: no-repeat;
              background-position: right .75rem center;
              background-size: 25px 25px;
              &:disabled {background: #f2f2f2;}
              &.error {
                border-color: #C6007E;background-color:rgba(#FF0000,0.08);
              }
            }
          }
        }
      }
      .note{font-size: 19px;font-weight:400;color: #4D4D4D;position: absolute;bottom:0;}
      ::v-deep(.note small) {
        font-size: 16px;
        margin: 10px 0;
        display: block;
      }
      ::v-deep(.note small + p) {
        margin-bottom: 0;
        margin-top:10px;
      }
    }
  }
}
.buttons {position: absolute;left: 0;bottom: 0;width: 100%;height: 75px;font-size: 30px;cursor: pointer;
  .card-button {width: 100%;height: 100%;font-size:35px;background: #0092BC;color: #fff;font-weight: 700;border:2px solid #fff;border-radius:35px;text-align: center;cursor: pointer;
    &:disabled {background: #C1C6C8;cursor: not-allowed;}
    svg{vertical-align: middle;}
  }
}
</style>