<template>
  <div v-if="questionData">
    <div class="true-false card">
      <div class="header">
        <h2>True or False</h2>
      </div>
      <div class="body">
        <div v-show="!questionData.showError && !questionData.showCorrect && !questionData.showReview" class="card-content">
          <div class="centered-content">
            <div class="question" v-html="activityContent.Content"></div>
            <div class="radio-group">
              <div :class="['radio-button', 'true-button', { active: questionData.answer === true }]" @click="setAnswer(true)">TRUE</div>
              <div :class="['radio-button', 'false-button', { active: questionData.answer === false }]" @click="setAnswer(false)">FALSE</div>
            </div>
          </div>
          <div class="note" v-html="activityContent.Note"></div>
        </div>
        <CorrectComponent  v-show="questionData.showCorrect"></CorrectComponent>
        <ErrorComponent v-show="questionData.showError" :questionData="questionData" :activityContent="activityContent" :answers="[]" :answer="activityContent.IsTrue ? 'TRUE' : 'FALSE'"></ErrorComponent>
        <ReviewComponent v-if="questionData.showReview" :questionData="questionData" :activityContent="activityContent" @seenReview="seenReview"></ReviewComponent>
      </div>
    </div>
    <TimerComponent ref="activityTimer" v-if="getCardsShowing" @timeout="handleTimeout" />
    <div class="points" :class="{'active':questionData.points !== null}">
      <div class="content">
        <span class="p">{{formatNumber(questionData.points,gameType)}}</span>
        <span class="text">Points</span>
      </div>
    </div>
    <div class="buttons">
      <button v-if="!questionData.showError && !questionData.showCorrect && !questionData.showReview" :disabled="questionData.answer === ''" class="card-button" @click="submitAnswer">Submit Final Answer <font-awesome-icon :icon="'angle-right'" /></button>
      <button v-if="!questionData.timedOut && questionData.showError && (questionData.errors < activityContent.NumAttempts || activityContent.NumAttempts === 0)" class="card-button" @click="tryAgain">Try Again <font-awesome-icon :icon="'angle-right'" /></button>
      <button v-if="(questionData.timedOut && questionData.showError) || questionData.showCorrect || questionData.showError && (questionData.errors >= activityContent.NumAttempts && activityContent.NumAttempts !== 0)" class="card-button" @click="enableReview">Review <font-awesome-icon :icon="'angle-right'" /></button>
      <button v-if="questionData.showReview" class="card-button" :disabled="!questionData.seenReview" @click="returnToGameBoard">Back to Gameboard  <font-awesome-icon :icon="'angle-right'" /></button>
    </div>
  </div>
  <div v-else>
    <div class="fill-in-blank card">{{questionData}}</div>
  </div>
</template>

<script>
import TimerComponent from './TimerComponent.vue';
import CorrectComponent from "@/components/game/CardComponents/CorrectComponent.vue";
import { CardMixin } from './CardMixin';
import {mapGetters} from "vuex";
import { ref } from 'vue';
import ErrorComponent from "@/components/game/CardComponents/ErrorComponent.vue";
import ReviewComponent from "@/components/game/CardComponents/ReviewComponent.vue";
export default {
  name: 'TrueFalseComponent',
  emits: ['hideCards', 'soundCorrect', 'soundIncorrect'],
  components: {
    ErrorComponent,
    TimerComponent,
    CorrectComponent,
    ReviewComponent
  },
  computed: {
    ...mapGetters(['getCardsShowing'])
  },
  mixins: [CardMixin],
  setup(props, { emit }) {
    const { questionData,updateQuestionDataInStore, calculatePoints, createSaveData, gameType  } = CardMixin.setup(props);
    const activityTimer = ref(null);
    const setAnswer = (answer) => {
      questionData.value.interacted = true;
      questionData.value.answer = answer;
      updateQuestionDataInStore(questionData.value);
    };
    const formatNumber = (value,gameType)=> {
      let formattedNumber = new Intl.NumberFormat('en-US').format(value);
      if(gameType ==='Random 1' || gameType ==='Random 2'){
        formattedNumber+='%';
      }
      return formattedNumber;
    };
    const submitAnswer = () => {
      if(props.activityContent.IsTrue === questionData.value.answer){
        questionData.value.showCorrect = true;
        questionData.value.correct = true;
        emit('soundCorrect');
      } else {
        emit('soundIncorrect');
        questionData.value.errors++;
        questionData.value.showError = true;
      }
      if(questionData.value.correct || (questionData.value.errors >= props.activityContent.NumAttempts &&  props.activityContent.NumAttempts !== 0)){
        if(!questionData.value.endTime){
          activityTimer.value.terminatenateWorker();
          questionData.value.endTime = Date.now();
          calculatePoints();
        }
      }
      updateQuestionDataInStore(questionData.value);
      createSaveData();
    };

    const returnToGameBoard = () => {
      emit('hideCards', questionData.value.points);
    };
    const handleTimeout = () => {
      questionData.value.timedOut = true;
      questionData.value.endTime = Date.now();
      emit('soundIncorrect');
      calculatePoints();
      questionData.value.showError = true;
      updateQuestionDataInStore(questionData.value);
      createSaveData();
    };

    const seenReview = () => {
        questionData.value.seenReview = true;
        updateQuestionDataInStore(questionData.value);
    };

    const tryAgain = () => {
      questionData.showError = false;
      questionData.answer = '';
      updateQuestionDataInStore(questionData.value);
    };

    const enableReview = async () => {
      questionData.value.showReview = true;
      questionData.value.showError = false;
      questionData.value.showCorrect = false;
      updateQuestionDataInStore(questionData.value);
    };

    return {
      returnToGameBoard,
      questionData,
      submitAnswer,
      handleTimeout,
      setAnswer,
      enableReview,
      tryAgain,
      activityTimer,
      seenReview,
      formatNumber,
      gameType
    };
  }
};
</script>

<style scoped lang="scss">
.points {width: 150px; height: 150px; position: absolute; left: 50%; top: 30px; transform: translate(-50%, -100%) scale(0); background: url(@/images/pointsBG.svg);background-size:contain; border-radius: 50%; display: flex; align-items: center; justify-content: center;color: #4d4d4d;opacity:0;transition: opacity 1s, transform 1.5s;
  .content{text-align: center;padding-top:25px;
    .p {font-size: 44px;font-weight: 700;display: block;width:100%;line-height:50px;}
    .text {font-size: 25px;font-weight: 700;display: block;width:100%;line-height:25px;}
  }
  &.active {transform: translate(-50%, -100%) scale(1);opacity:1;}
}
.card {width: 720px;height: 900px;background: #fff;position: absolute;top: 0;left: 50%;transform:translateX(-50%);border-radius: 11px;overflow: hidden;
  .header {background-color: #C1C6C8;width: 100%;height: 120px;position: relative;display: flex;align-items: center;justify-content: center;
    h2 {font-size: 40px;font-weight: 700;text-align: center;position: relative;z-index: 1;color: #4d4d4d;}
    &:after {content: '';position: absolute;top: 0;left: 0;width: 100%;height: 100%;background-image: url(@/images/cardHeader.svg);opacity: 0.19;}
  }
  .body {box-sizing: border-box;width: 100%;height: 780px;padding: 30px;overflow: auto;display: flex;align-items: center;
    .card-content{position: relative;height:100%;display: flex;align-items: top;width: 100%;
      .centered-content{width: 100%;
        .question{font-size: 25px;font-weight:700;color:#4D4D4D;}
        .radio-group{display: flex;align-items: center;justify-content: space-between;margin: 50px 0;
          .radio-button {width: 296px;height: 296px;border-radius: 50%;border: 2px solid;display: flex;align-items: center;justify-content: center;font-size: 50px;font-weight: bold;cursor: pointer;user-select: none;
            &.true-button {background: #fff;color: #0092BC;border-color:#0092BC;
              &:hover,&:focus {background:linear-gradient(to bottom,#fff,lighten(#0092BC,50%));}
              &.active {color: white;background:linear-gradient(to bottom,lighten(#0092BC,10%),#0092BC);}
            }
            &.false-button {background: #fff;color: #0092BC;border-color:#0092BC;
              &:hover,&:focus {background:linear-gradient(to bottom,#fff,lighten(#0092BC,50%));}
              &.active {color: white;background: linear-gradient(to bottom, lighten(#0092BC,30%), #0092BC);}
            }
          }
        }
      }
      .note{font-size: 19px;font-weight:400;color: #4D4D4D;position: absolute;bottom:0;}
      ::v-deep(.note small) {
        font-size: 16px;
        margin: 10px 0;
      }
      ::v-deep(.note small + p) {
        margin-bottom: 0;
        margin-top:10px;
      }
    }
  }
}
.buttons {position: absolute;left: 0;bottom: 0;width: 100%;height: 75px;font-size: 30px;cursor: pointer;
  .card-button {width: 100%;height: 100%;font-size:35px;background: #0092BC;color: #fff;font-weight: 700;border:2px solid #fff;border-radius:35px;text-align: center;cursor: pointer;
    &:disabled {background: #C1C6C8;cursor: not-allowed;}
    svg{vertical-align: middle;}
  }
}
</style>
