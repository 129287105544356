export const gameboardModule = {
    namespaced: false,
    state: {
        isCompatible: false,
        performanceData: null,
        checkCompatibility: true,
        checkPerformance: false,
        gameReady: false,
        jwt: null,
        token: null,
        masterVolumeState: false,
        game: {}, // Initialize as an object to store game data
        currentSpace: 0,
        currentSpaceType: null,
        isAnimating: false,
        gameStarted: false,
        gameEnded: false,
        animatingToSpace: false,
        alreadyPlayed: false,
        roll: 0,
        myTrack: null,
        currentScore: 0,
        progress: 0,
        cardsShowing: false,
        fillInBlank:0,
        multipleChoice:0,
        trueFalse:0,
        unscramble:0,
        questions:{},
        showISI: false,
        welcome:true,
        welcomeComplete: false,
        howtoplay1:false,
        howtoplay2:false,
    },
    mutations: {
        setWelcome(state, payload) {
            state.welcome = payload;
        },
        setHowToPlay1(state, payload) {
            state.howtoplay1 = payload;
        },
        setHowToPlay2(state, payload) {
            state.howtoplay2 = payload;
        },
        setTimer(state, { uniqueId, timer }) {
            if (state.questions[uniqueId]) {
                state.questions[uniqueId].timer = timer;
            }
        },
        setShowISI(state, payload) {
            state.showISI = payload;
        },
        setEndTime(state, { uniqueId, endTime }) {
            if (state.questions[uniqueId]) {
                state.questions[uniqueId].endTime = endTime;
            }
        },
        setStartTime(state, { uniqueId, startTime }) {
            if (state.questions[uniqueId]) {
                state.questions[uniqueId].startTime = startTime;
            }
        },
        setPoints(state, { uniqueId, points }) {
            if (state.questions[uniqueId]) {
                state.questions[uniqueId].points = points;
            }
        },
        setTimeOut(state, { uniqueId, timedOut }) {
            if (state.questions[uniqueId]) {
                state.questions[uniqueId].timedOut = timedOut;
            }
        },
        setAnimatingToSpace(state, payload) {
            state.animatingToSpace = payload;
        },
        setQuestion(state, payload) {
            state.questions[payload.uniqueId] = payload;
        },
        setFillInBlank(state, payload) {
            state.fillInBlank = payload;
        },
        increaseFillInBlank(state) {
            state.fillInBlank++;
        },
        setMultipleChoice(state, payload) {
            state.multipleChoice = payload;
        },
        increaseMultipleChoice(state) {
            state.multipleChoice++;
        },
        setTrueFalse(state, payload) {
            state.trueFalse = payload;
        },
        increaseTrueFalse(state) {
            state.trueFalse++;
        },
        setUnscramble(state, payload) {
            state.unscramble = payload;
        },
        increaseUnscramble(state) {
            state.unscramble++;
        },
        setCardsShowing(state, payload) {
            state.cardsShowing = payload;
        },
        setCurrentSpaceType(state, payload) {
            state.currentSpaceType = payload;
        },
        setProgress(state, payload) {
            state.progress = payload;
        },
        setMyTrack(state, payload) {
            state.myTrack = payload;
        },
        setGameEnded(state, payload) {
            state.gameEnded = payload;
        },
        setCurrentSpace(state, payload) {
            state.currentSpace = payload;
        },
        setGameStarted(state, payload) {
            state.gameStarted = payload;
        },
        setAnimating(state, payload) {
            state.isAnimating = payload;
        },
        setCompatibility(state, payload) {
            state.isCompatible = payload;
            state.checkCompatibility = false;
            state.checkPerformance = payload;
        },
        setPerformanceData(state, payload) {
            state.performanceData = payload;
            state.checkPerformance = false;
            state.gameReady = true;
        },
        setCombinedRating(state, payload) {
            if (state.performanceData) {
                state.performanceData.combinedRating = payload;
            }
        },
        setGpuFps(state, payload) {
            if (state.performanceData && state.performanceData.gpu) {
                state.performanceData.gpu.fpsAvg = payload;
            }
        },
        setCurrentScore(state, payload) {
            state.currentScore = payload;
        },
        addToCurrentScore(state, increment) {
            state.currentScore += increment;
        },
        resetPerformanceData(state) {
            state.isCompatible = false;
            state.performanceData = null;
            state.checkCompatibility = true;
            state.checkPerformance = false;
            state.gameReady = false;
        },
        resetAudio(state) {
            state.masterVolumeState = false;
        },
        resetGame(state) {
            state.currentSpace = 0;
            state.isAnimating = false;
            state.gameStarted = false;
            state.animatingToSpace = false;
            state.alreadyPlayed = false;
            state.roll = 0;
            state.myTrack = null;
            state.gameEnded = false;
            state.currentSpaceType = null;
            state.currentScore = 0;
            state.progress = 0;
            state.cardsShowing = false;
            state.fillInBlank = 0;
            state.multipleChoice = 0;
            state.trueFalse = 0;
            state.unscramble = 0;
            state.questions = {};
            state.showISI = false;
            state.welcome = true;
            state.howtoplay1 = false;
            state.howtoplay2 = false;
            state.welcomeComplete = false;
        },
        setWelcomeComplete(state, payload) {
            state.welcomeComplete = payload;
        },
        setUserData(state, { jwt, game, token }) {
            state.jwt = jwt;
            state.game = game;
            state.token = token;
        },
        clearUserData(state) {
            state.jwt = null;
            state.game = {};
            state.token = null;
        },
        setMasterVolumeState(state, payload) {
            state.masterVolumeState = payload;
        },
        setAlreadyPlayed(state, payload) {
            state.alreadyPlayed = payload;
        },
        setRoll(state, payload) {
            state.roll = payload;
        },
        toggleISI(state) {
            state.showISI = !state.showISI;
        }
    },
    actions: {
        setWelcomeComplete({ commit }, payload) {
            commit('setWelcomeComplete', payload);
        },
        setWelcome({ commit }, payload) {
            commit('setWelcome', payload);
        },
        setHowToPlay1({ commit }, payload) {
            commit('setHowToPlay1', payload);
        },
        setHowToPlay2({ commit }, payload) {
            commit('setHowToPlay2', payload);
        },
        setShowISI({ commit }, payload) {
            commit('setShowISI', payload);
        },
        toggleISI({ state, commit }) {
            commit('setShowISI', !state.showISI);
        },
        setTimer({ commit }, payload) {
            commit('setTimer', payload);
        },
        setEndTime({ commit }, payload) {
            commit('setEndTime', payload);
        },
        setStartTime({ commit }, payload) {
            commit('setStartTime', payload);
        },
        setTimeOut({ commit }, payload) {
            commit('setTimeOut', payload);
        },
        setPoints({ commit }, payload) {
            commit('setPoints', payload);
        },
        handleCompatibility({ commit }, result) {
            commit('setCompatibility', result);
        },
        handlePerformanceRating({ commit }, rating) {
            commit('setPerformanceData', rating);
        },
        setCardsShowing({ commit }, payload) {
            commit('setCardsShowing', payload);
        },
        setCombinedRating({ commit }, data) {
            commit('setCombinedRating', data);
        },
        setFillInBlank({ commit }, payload) {
            commit('setFillInBlank', payload);
        },
        setIncrementFillInBlank({ commit }) {
            commit('increaseFillInBlank');
        },
        setMultipleChoice({ commit }, payload) {
            commit('setMultipleChoice', payload);
        },
        setIncrementMultipleChoice({ commit }) {
            commit('increaseMultipleChoice');
        },
        setTrueFalse({ commit }, payload) {
            commit('setTrueFalse', payload);
        },
        setIncrementTrueFalse({ commit }) {
            commit('increaseTrueFalse');
        },
        setUnscramble({ commit }, payload) {
            commit('setUnscramble', payload);
        },
        setIncrementUnscramble({ commit }) {
            commit('increaseUnscramble');
        },
        setHash({ commit }, payload) {
            commit('setHash', payload);
        },
        resetGameData({ commit }) {
            commit('resetPerformanceData');
            commit('clearUserData');
            commit('resetAudio');
            commit('resetGame');
        },
        resetGame({ commit }) {
            commit('resetAudio');
            commit('resetGame');
        },
        setQuestion({ commit }, payload) {
            commit('setQuestion', payload);
        },
        setProgress({ commit }, payload) {
            commit('setProgress', payload);
        },
        setCurrentScore({ commit }, payload) {
            commit('setCurrentScore', payload);
        },
        saveUserData({ commit }, data) {
            commit('setUserData', data);
        },
        setMasterVolumeState({ commit }, payload) {
            commit('setMasterVolumeState', payload);
        },
        setCurrentSpace({ commit }, payload) {
            commit('setCurrentSpace', payload);
        },
        addToCurrentScore({ commit }, increment) {
            commit('addToCurrentScore', increment);
        },
        setAnimating({ commit }, payload) {
            commit('setAnimating', payload);
        },
        setGameStarted({ commit }, payload) {
            commit('setGameStarted', payload);
        },
        setAnimatingToSpace({ commit }, payload) {
            commit('setAnimatingToSpace', payload);
        },
        setAlreadyPlayed({ commit }, payload) {
            commit('setAlreadyPlayed', payload);
        },
        setGpuFps({ commit }, payload) {
            commit('setGpuFps', payload);
        },
        setRoll({ commit }, payload) {
            commit('setRoll', payload);
        },
        setMyTrack({ commit }, payload) {
            commit('setMyTrack', payload);
        },
        setGameEnded({ commit }, payload) {
            commit('setGameEnded', payload);
        },
        setCurrentSpaceType({ commit }, payload) {
            commit('setCurrentSpaceType', payload);
        }
    },
    getters: {
        getJWT: state => state.jwt,
        getQuestion: state => uniqueId => {
            return state.questions[uniqueId];
        },
        getToken: state => state.token,
        getSavedQuestions: state => state.questions,
        getShowISI: state => state.showISI,
        getMasterVolumeState: state => state.masterVolumeState,
        getCurrentSpace: state => state.currentSpace,
        getPerformanceData: state => state.performanceData,
        getIsAnimating: state => state.isAnimating,
        getGameStarted: state => state.gameStarted,
        getAnimatingToSpace: state => state.animatingToSpace,
        getAlreadyPlayed: state => state.alreadyPlayed,
        getRoll: state => state.roll,
        getMyTrack: state => state.myTrack,
        getCtaEnabled: state => state.game.CtaEnabled,
        getCtaContent: state => state.game.CtaContent,
        getCtaLink: state => state.game.CtaLink,
        getCtaLinkText: state => state.game.CtaLinkText,
        getFixedTrack: state => state.game ? state.game.FixedTrack : null,
        getPRB: state => {
            if (state.game) {
                if (state.game.QuestionSet){
                    if (state.game.QuestionSet.PRB) {
                        return state.game.QuestionSet.PRB;
                    } else {
                        return null;
                    }
                }else{
                    return null;
                }
            }
            return null; // Return null if state.game is not available
        },
        getGameEnded: state => state.gameEnded,
        getCurrentSpaceType: state => state.currentSpaceType,
        getCurrentScore: state => state.currentScore,
        getProgress: state => state.progress,
        getCardsShowing: state => state.cardsShowing,
        getFillInBlank: state => state.fillInBlank,
        getMultipleChoice: state => state.multipleChoice,
        getTrueFalse: state => state.trueFalse,
        getUnscramble: state => state.unscramble,
        getWelcome: state => state.welcome,
        getWelcomeComplete: state => state.welcomeComplete,
        getHowToPlay1: state => state.howtoplay1,
        getHowToPlay2: state => state.howtoplay2,
        getGameType: state => {
            if (state.game) {
                if (state.game.QuestionSet){
                    if (state.game.QuestionSet.GameType !== 'Random 1' && state.game.QuestionSet.GameType !== 'Random 2') {
                        return parseInt(state.game.QuestionSet.GameType);
                    } else {
                        return state.game.QuestionSet.GameType;
                    }
                }
            }
            return null; // Return null if state.game is not available
        },
        getQuestions: state => {
            if (state.game) {
                if (state.game.QuestionSet) {
                    return state.game.QuestionSet.Questions;
                }
            }
            return null;
        },
        getGameID: state => {
            if (state.game) {
                return state.game.id;
            }
            return null;
        },
        getQuestionSetHighScore: state => {
            if (state.game) {
                if (state.game.QuestionSet) {
                    return state.game.QuestionSet.HighScore;
                }
            }
            return null;
        },
    }
}