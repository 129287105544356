<template>
  <div v-if="gameStarted" class="logo">
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
  setup() {
    const store = useStore();
    const gameStarted = computed(() => store.getters.getGameStarted);
    const gameEnded = computed(() => store.getters.getGameEnded);
    const questionSetHighScore = computed(() => store.getters.getQuestionSetHighScore);
    const currentScore = computed(() => store.getters.getCurrentScore);
    const gameType = computed(() => store.getters.getGameType);
    const formatNumber = (value,gameType)=> {
      let formattedNumber = new Intl.NumberFormat('en-US').format(value);
      if(gameType ==='Random 1' || gameType ==='Random 2'){
        formattedNumber+='%';
      }
      return formattedNumber;
    };
    return { gameStarted, gameEnded, questionSetHighScore, currentScore,formatNumber,gameType };
  }
}
</script>

<style scoped lang="scss">
.logo {position: absolute;top: 0;background-size: cover;text-align: center;background-size: cover;background-image: url(@/images/logo.svg);z-index:4;
  img {height: auto;width: 100%;display: block;}
}
@media screen and (max-aspect-ratio: 1/1) {
  .logo {right: min(2vw,15px);height: min(17.5vw,131.25px);width: min(25vw,187.5px);}
}
@media screen and (min-aspect-ratio: 1/1) {
  .logo {right: min(2vh,15px);height: min(17.5vh,131.25px);width: min(25vh,187.5px);}
}
</style>
