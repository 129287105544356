<template>
  <div v-if="questionData">
    <div class="unscramble card">
      <div class="header">
        <h2>Unscramble</h2>
      </div>
      <div class="body">
        <div v-show="!questionData.showError && !questionData.showCorrect && !questionData.showReview" class="card-content">
          <div class="centered-content">
            <div class="question" v-html="activityContent.Content"></div>
            <div class="unscramble-group">
              <div class="unscramble-word" :class="'scramble-'+activityContent.ScrambledPhrase.length">
                <button :class="'letter-'+(index+1)" v-for="(letter, index) in activityContent.ScrambledPhrase" :disabled="questionData.inError.includes(index)" :key="index" @click="addLetter(letter,index)">{{letter}}</button>
              </div>
              <div class="controls">
                <input type="text" v-model="questionData.answer" readonly="readonly" placeholder="Click/Tap letters above to add to word" />
                <button v-if="questionData.answer" class="unscramble-btn delete" @click="backspace"><font-awesome-icon :icon="'delete-left'" /></button>
              </div>
            </div>
          </div>
          <div class="note" v-html="activityContent.Note"></div>
        </div>
        <CorrectComponent  v-show="questionData.showCorrect"></CorrectComponent>
        <ErrorComponent v-show="questionData.showError" :questionData="questionData" :activityContent="activityContent" :answers="[]" :answer="activityContent.Phrase"></ErrorComponent>
        <ReviewComponent v-if="questionData.showReview" :questionData="questionData" :activityContent="activityContent" @seenReview="seenReview"></ReviewComponent>
      </div>
    </div>
    <TimerComponent ref="activityTimer" v-if="getCardsShowing" @timeout="handleTimeout" />
    <div class="points" :class="{'active':questionData.points !== null}">
      <div class="content">
        <span class="p">{{formatNumber(questionData.points,gameType)}}</span>
        <span class="text">Points</span>
      </div>
    </div>
    <div class="buttons">
      <button v-if="!questionData.showError && !questionData.showCorrect && !questionData.showReview" :disabled="questionData.answer.length !== activityContent.Phrase.length" class="card-button" @click="submitAnswer">Submit Final Answer <font-awesome-icon :icon="'angle-right'" /></button>
      <button v-if="!questionData.timedOut && questionData.showError && (questionData.errors < activityContent.NumAttempts || activityContent.NumAttempts === 0)" class="card-button" @click="tryAgain">Try Again <font-awesome-icon :icon="'angle-right'" /></button>
      <button v-if="(questionData.timedOut && questionData.showError) || questionData.showCorrect || questionData.showError && (questionData.errors >= activityContent.NumAttempts && activityContent.NumAttempts !== 0)" class="card-button" @click="enableReview">Review <font-awesome-icon :icon="'angle-right'" /></button>
      <button v-if="questionData.showReview" class="card-button" :disabled="!questionData.seenReview" @click="returnToGameBoard">Back to Gameboard  <font-awesome-icon :icon="'angle-right'" /></button>
    </div>
  </div>
  <div v-else>
    <div class="fill-in-blank card">{{questionData}}</div>
  </div>
</template>
<script>
import TimerComponent from './TimerComponent.vue';
import { CardMixin } from './CardMixin';
import {mapGetters} from "vuex";
import {ref} from "vue";
import CorrectComponent from "@/components/game/CardComponents/CorrectComponent.vue";
import ErrorComponent from "@/components/game/CardComponents/ErrorComponent.vue";
import ReviewComponent from "@/components/game/CardComponents/ReviewComponent.vue";
export default {
  name: 'UnscrambleComponent',
  components: {
    ErrorComponent,
    CorrectComponent,
    TimerComponent,
    ReviewComponent
  },
  emits: ['hideCards','soundCorrect', 'soundIncorrect'],
  computed: {
    ...mapGetters(['getCardsShowing'])
  },
  mixins: [CardMixin],
  setup(props, { emit }) {
    const { questionData,updateQuestionDataInStore, calculatePoints,createSaveData, gameType  } = CardMixin.setup(props);
    const activityTimer = ref(null);
    const formatNumber = (value,gameType)=> {
      let formattedNumber = new Intl.NumberFormat('en-US').format(value);
      if(gameType ==='Random 1' || gameType ==='Random 2'){
        formattedNumber+='%';
      }
      return formattedNumber;
    };
    const backspace = () => {
      questionData.value.answer = questionData.value.answer.slice(0, -1);
      questionData.value.inError.pop();
      updateQuestionDataInStore(questionData.value);
    };
    const addLetter = (letter,index) => {
      questionData.value.answer += letter;
      questionData.value.inError.push(index);
      updateQuestionDataInStore(questionData.value);
    };
    const submitAnswer = () => {
      // find the correct answer :data-correct is true and compare it to the answer
      if(props.activityContent.Phrase === questionData.value.answer){
        questionData.value.showCorrect = true;
        questionData.value.correct = true;
        emit('soundCorrect');
      } else {
        questionData.value.errors++;
        questionData.value.showError = true;
        emit('soundIncorrect');
      }
      if(questionData.value.correct || (questionData.value.errors >= props.activityContent.NumAttempts &&  props.activityContent.NumAttempts !== 0)){
        if(!questionData.value.endTime){
          activityTimer.value.terminatenateWorker();
          questionData.value.endTime = Date.now();
          calculatePoints();
        }
      }
      updateQuestionDataInStore(questionData.value);
      createSaveData();
    };

    const returnToGameBoard = () => {
      emit('hideCards', questionData.value.points);
    };
    const handleTimeout = () => {
      questionData.value.timedOut = true;
      questionData.value.endTime = Date.now();
      calculatePoints();
      emit('soundIncorrect');
      questionData.value.showError = true;
      updateQuestionDataInStore(questionData.value);
      createSaveData();
    };

    const seenReview = () => {
      questionData.value.seenReview = true;
      updateQuestionDataInStore(questionData.value);
    };

    const tryAgain = () => {
      questionData.value.showError = false;
      questionData.value.answer = '';
      questionData.value.inError = [];
      updateQuestionDataInStore(questionData.value);
    };

    const enableReview = async () => {
      questionData.value.showReview = true;
      questionData.value.showError = false;
      questionData.value.showCorrect = false;
      updateQuestionDataInStore(questionData.value);
    };

    return {
      returnToGameBoard,
      questionData,
      submitAnswer,
      handleTimeout,
      enableReview,
      tryAgain,
      activityTimer,
      seenReview,
      formatNumber,
      backspace,
      addLetter,
      gameType
    };
  }
};
</script>

<style  scoped lang="scss">
.points {width: 150px; height: 150px; position: absolute; left: 50%; top: 30px; transform: translate(-50%, -100%) scale(0); background: url(@/images/pointsBG.svg);background-size:contain; border-radius: 50%; display: flex; align-items: center; justify-content: center;color: #4d4d4d;opacity:0;transition: opacity 1s, transform 1.5s;
  .content{text-align: center;padding-top:25px;
    .p {font-size: 44px;font-weight: 700;display: block;width:100%;line-height:50px;}
    .text {font-size: 25px;font-weight: 700;display: block;width:100%;line-height:25px;}
  }
  &.active {transform: translate(-50%, -100%) scale(1);opacity:1;}
}
.card {width: 720px;height: 900px;background: #fff;position: absolute;top: 0;left: 50%;transform:translateX(-50%);border-radius: 11px;overflow: hidden;
  .header {background-color: #440099;width: 100%;height: 120px;position: relative;display: flex;align-items: center;justify-content: center;
    h2 {font-size: 40px;font-weight: 700;text-align: center;position: relative;z-index: 1;color: #fff;}
    &:after {content: '';position: absolute;top: 0;left: 0;width: 100%;height: 100%;background-image: url(@/images/cardHeader.svg);opacity: 0.19;}
  }
  .body {box-sizing: border-box;width: 100%;height: 780px;padding:10px 30px;overflow: auto;display: flex;align-items: center;
    .card-content{position: relative;height:100%;display: flex;align-items: top;width: 100%;
      .centered-content{width: 100%;
        .question{font-size: 25px;font-weight:700;color:#4D4D4D;}
        .unscramble-group{
          .unscramble-word{position: relative;width: 660px;height: 390px;margin: auto;
            button{ -webkit-appearance:none;position: absolute;width: 168px;height: 115px;background-color: #fff;color: #4D4D4D;font-size: 40px;clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);cursor: pointer;border: none;outline: none;z-index: 1;
              &::before {content: "";position: absolute;top: 4px;  /* Adjust the offset to match the border thickness */left: 4px; /* Adjust the offset to match the border thickness */right: 4px; /* Adjust the offset to match the border thickness */bottom: 4px; /* Adjust the offset to match the border thickness */background-color: #EEE9F6;clip-path: inherit;  /* Use the same clip-path as the button */z-index: -1;}
              &:hover,&:focus{color: #fff;
                &::before {background-color: #0092BC;}
              }
              &:disabled{color: #C1C6C8;cursor: not-allowed;
                &::before {background-color: #eee;}
              }
            }
            &.scramble-3{
              .letter-1{ top: 50%;left: 50%;transform: translate(-100%,-75%);}
              .letter-2 {top: 50%;left: 50%;transform: translate(-0%,-75%);}
              .letter-3 {top: 50%;left: 50%;transform: translate(-50%,-25%);}
            }
            &.scramble-4{
              .letter-1{ top: 50%;left: 50%;transform: translate(-50%,-125%);}
              .letter-2{ top: 50%;left: 50%;transform: translate(-100%,-75%);}
              .letter-3 {top: 50%;left: 50%;transform: translate(-0%,-75%);}
              .letter-4 {top: 50%;left: 50%;transform: translate(-50%,-25%);}
            }
            &.scramble-5{
              .letter-1{ top: 50%;left: 50%;transform: translate(-100%,-100%);}
              .letter-2{ top: 50%;left: 50%;transform: translate(0%,-100%);}
              .letter-3 {top: 50%;left: 50%;transform: translate(-50%,-50%);}
              .letter-4 {top: 50%;left: 50%;transform: translate(-100%,0%);}
              .letter-5 {top: 50%;left: 50%;transform: translate(0%,0%);}
            }
            &.scramble-6{
              .letter-1{ top: 50%;left: 50%;transform: translate(-50%,-150%);}
              .letter-2{ top: 50%;left: 50%;transform: translate(-100%,-100%);}
              .letter-3{ top: 50%;left: 50%;transform: translate(0%,-100%);}
              .letter-4 {top: 50%;left: 50%;transform: translate(-50%,-50%);}
              .letter-5 {top: 50%;left: 50%;transform: translate(-100%,0%);}
              .letter-6 {top: 50%;left: 50%;transform: translate(0%,0%);}
            }
            &.scramble-7{
              .letter-1{ top: 50%;left: 50%;transform: translate(-100%,-125%);}
              .letter-2{ top: 50%;left: 50%;transform: translate(0%,-125%);}
              .letter-3 {top: 50%;left: 50%;transform: translate(-150%,-75%);}
              .letter-4 {top: 50%;left: 50%;transform: translate(-50%,-75%);}
              .letter-5 {top: 50%;left: 50%;transform: translate(50%,-75%);}
              .letter-6 {top: 50%;left: 50%;transform: translate(-100%,-25%);}
              .letter-7 {top: 50%;left: 50%;transform: translate(0%,-25%);}
            }
            &.scramble-8{
              .letter-1{ top: 50%;left: 50%;transform: translate(-100%,-125%);}
              .letter-2{ top: 50%;left: 50%;transform: translate(0%,-125%);}
              .letter-3 {top: 50%;left: 50%;transform: translate(-150%,-75%);}
              .letter-4 {top: 50%;left: 50%;transform: translate(-50%,-75%);}
              .letter-5 {top: 50%;left: 50%;transform: translate(50%,-75%);}
              .letter-6 {top: 50%;left: 50%;transform: translate(-100%,-25%);}
              .letter-7 {top: 50%;left: 50%;transform: translate(0%,-25%);}
              .letter-8 {top: 50%;left: 50%;transform: translate(-50%,25%);}
            }
            &.scramble-9{
              .letter-1{ top: 50%;left: 50%;transform: translate(-50%,-175%);}
              .letter-2{ top: 50%;left: 50%;transform: translate(-100%,-125%);}
              .letter-3 {top: 50%;left: 50%;transform: translate(0%,-125%);}
              .letter-4 {top: 50%;left: 50%;transform: translate(-150%,-75%);}
              .letter-5 {top: 50%;left: 50%;transform: translate(-50%,-75%);}
              .letter-6 {top: 50%;left: 50%;transform: translate(50%,-75%);}
              .letter-7 {top: 50%;left: 50%;transform: translate(-100%,-25%);}
              .letter-8 {top: 50%;left: 50%;transform: translate(0%,-25%);}
              .letter-9 {top: 50%;left: 50%;transform: translate(-50%,25%);}
            }
            &.scramble-10{
              .letter-1{ top: 50%;left: 50%;transform: translate(-150%,-125%);}
              .letter-2{ top: 50%;left: 50%;transform: translate(-50%,-125%);}
              .letter-3{ top: 50%;left: 50%;transform: translate(50%,-125%);}
              .letter-4{ top: 50%;left: 50%;transform: translate(-100%,-75%);}
              .letter-5 {top: 50%;left: 50%;transform: translate(-0%,-75%);}
              .letter-6 {top: 50%;left: 50%;transform: translate(-150%,-25%);}
              .letter-7 {top: 50%;left: 50%;transform: translate(-50%,-25%);}
              .letter-8 {top: 50%;left: 50%;transform: translate(50%,-25%);}
              .letter-9{ top: 50%;left: 50%;transform: translate(-100%,25%);}
              .letter-10 {top: 50%;left: 50%;transform: translate(-0%,25%);}
            }
            &.scramble-11{
              .letter-1{ top: 50%;left: 50%;transform: translate(-150%,-175%);}
              .letter-2{ top: 50%;left: 50%;transform: translate(-50%,-175%);}
              .letter-3{ top: 50%;left: 50%;transform: translate(50%,-175%);}
              .letter-4{ top: 50%;left: 50%;transform: translate(-100%,-125%);}
              .letter-5 {top: 50%;left: 50%;transform: translate(-0%,-125%);}
              .letter-6 {top: 50%;left: 50%;transform: translate(-150%,-75%);}
              .letter-7 {top: 50%;left: 50%;transform: translate(-50%,-75%);}
              .letter-8 {top: 50%;left: 50%;transform: translate(50%,-75%);}
              .letter-9{ top: 50%;left: 50%;transform: translate(-100%,-25%);}
              .letter-10 {top: 50%;left: 50%;transform: translate(-0%,-25%);}
              .letter-11 {top: 50%;left: 50%;transform: translate(-50%,25%);}
            }
            &.scramble-12{
              .letter-1{ top: 50%;left: 50%;transform: translate(-100%,-175%);}
              .letter-2{ top: 50%;left: 50%;transform: translate(0%,-157%);}
              .letter-3{ top: 50%;left: 50%;transform: translate(-150%,-125%);}
              .letter-4{ top: 50%;left: 50%;transform: translate(-50%,-125%);}
              .letter-5 {top: 50%;left: 50%;transform: translate(50%,-125%);}
              .letter-6 {top: 50%;left: 50%;transform: translate(-100%,-75%);}
              .letter-7 {top: 50%;left: 50%;transform: translate(-0%,-75%);}
              .letter-8 {top: 50%;left: 50%;transform: translate(-150%,-25%);}
              .letter-9{ top: 50%;left: 50%;transform: translate(-50%,-25%);}
              .letter-10 {top: 50%;left: 50%;transform: translate(50%,-25%);}
              .letter-11 {top: 50%;left: 50%;transform: translate(-100%,25%);}
              .letter-12 {top: 50%;left: 50%;transform: translate(0%,25%);}
            }
            &.scramble-13{
              .letter-1{ top: 50%;left: 50%;transform: translate(-150%,-175%);}
              .letter-2{ top: 50%;left: 50%;transform: translate(-50%,-175%);}
              .letter-3{ top: 50%;left: 50%;transform: translate(50%,-175%);}
              .letter-4{ top: 50%;left: 50%;transform: translate(-200%,-125%);}
              .letter-5 {top: 50%;left: 50%;transform: translate(-100%,-125%);}
              .letter-6 {top: 50%;left: 50%;transform: translate(0%,-125%);}
              .letter-7 {top: 50%;left: 50%;transform: translate(100%,-125%);}
              .letter-8 {top: 50%;left: 50%;transform: translate(-150%,-75%);}
              .letter-9{ top: 50%;left: 50%;transform: translate(-50%,-75%);}
              .letter-10 {top: 50%;left: 50%;transform: translate(50%,-75%);}
              .letter-11{ top: 50%;left: 50%;transform: translate(-100%,-25%);}
              .letter-12 {top: 50%;left: 50%;transform: translate(-0%,-25%);}
              .letter-13 {top: 50%;left: 50%;transform: translate(-50%,25%);}
            }
            &.scramble-14{
              .letter-1{ top: 50%;left: 50%;transform: translate(-100%,-175%);}
              .letter-2{ top: 50%;left: 50%;transform: translate(0%,-175%);}
              .letter-3{ top: 50%;left: 50%;transform: translate(-150%,-125%);}
              .letter-4{ top: 50%;left: 50%;transform: translate(-50%,-125%);}
              .letter-5 {top: 50%;left: 50%;transform: translate(50%,-125%);}
              .letter-6 {top: 50%;left: 50%;transform: translate(-200%,-75%);}
              .letter-7 {top: 50%;left: 50%;transform: translate(-100%,-75%);}
              .letter-8 {top: 50%;left: 50%;transform: translate(0%,-75%);}
              .letter-9{ top: 50%;left: 50%;transform: translate(100%,-75%);}
              .letter-10 {top: 50%;left: 50%;transform: translate(-150%,-25%);}
              .letter-11 {top: 50%;left: 50%;transform: translate(-50%,-25%);}
              .letter-12 {top: 50%;left: 50%;transform: translate(50%,-25%);}
              .letter-13 {top: 50%;left: 50%;transform: translate(-100%,25%);}
              .letter-14 {top: 50%;left: 50%;transform: translate(0%,25%);}
            }
            &.scramble-15{
              .letter-1{ top: 50%;left: 50%;transform: translate(-150%,-175%);}
              .letter-2{ top: 50%;left: 50%;transform: translate(-50%,-175%);}
              .letter-3{ top: 50%;left: 50%;transform: translate(50%,-175%);}
              .letter-4{ top: 50%;left: 50%;transform: translate(-200%,-125%);}
              .letter-5 {top: 50%;left: 50%;transform: translate(-100%,-125%);}
              .letter-6 {top: 50%;left: 50%;transform: translate(0%,-125%);}
              .letter-7 {top: 50%;left: 50%;transform: translate(100%,-125%);}
              .letter-8 {top: 50%;left: 50%;transform: translate(-150%,-75%);}
              .letter-9{ top: 50%;left: 50%;transform: translate(-50%,-75%);}
              .letter-10 {top: 50%;left: 50%;transform: translate(50%,-75%);}
              .letter-11{ top: 50%;left: 50%;transform: translate(-100%,-25%);}
              .letter-12 {top: 50%;left: 50%;transform: translate(-0%,-25%);}
              .letter-13 {top: 50%;left: 50%;transform: translate(-150%,25%);}
              .letter-14 {top: 50%;left: 50%;transform: translate(-50%,25%);}
              .letter-15 {top: 50%;left: 50%;transform: translate(50%,25%);}
            }
            &.scramble-16{
              .letter-1{ top: 50%;left: 50%;transform: translate(-150%,-175%);}
              .letter-2{ top: 50%;left: 50%;transform: translate(-50%,-175%);}
              .letter-3{ top: 50%;left: 50%;transform: translate(50%,-175%);}
              .letter-4{ top: 50%;left: 50%;transform: translate(-200%,-125%);}
              .letter-5 {top: 50%;left: 50%;transform: translate(-100%,-125%);}
              .letter-6 {top: 50%;left: 50%;transform: translate(0%,-125%);}
              .letter-7 {top: 50%;left: 50%;transform: translate(100%,-125%);}
              .letter-8 {top: 50%;left: 50%;transform: translate(-150%,-75%);}
              .letter-9{ top: 50%;left: 50%;transform: translate(50%,-75%);}
              .letter-10 {top: 50%;left: 50%;transform: translate(-200%,-25%);}
              .letter-11{ top: 50%;left: 50%;transform: translate(-100%,-25%);}
              .letter-12 {top: 50%;left: 50%;transform: translate(-0%,-25%);}
              .letter-13 {top: 50%;left: 50%;transform: translate(100%,-25%);}
              .letter-14 {top: 50%;left: 50%;transform: translate(-150%,25%);}
              .letter-15 {top: 50%;left: 50%;transform: translate(-50%,25%);}
              .letter-16 {top: 50%;left: 50%;transform: translate(50%,25%);}
            }
            &.scramble-17{
              .letter-1{ top: 50%;left: 50%;transform: translate(-150%,-175%);}
              .letter-2{ top: 50%;left: 50%;transform: translate(-50%,-175%);}
              .letter-3{ top: 50%;left: 50%;transform: translate(50%,-175%);}
              .letter-4{ top: 50%;left: 50%;transform: translate(-200%,-125%);}
              .letter-5 {top: 50%;left: 50%;transform: translate(-100%,-125%);}
              .letter-6 {top: 50%;left: 50%;transform: translate(0%,-125%);}
              .letter-7 {top: 50%;left: 50%;transform: translate(100%,-125%);}
              .letter-8 {top: 50%;left: 50%;transform: translate(-150%,-75%);}
              .letter-9{ top: 50%;left: 50%;transform: translate(-50%,-75%);}
              .letter-10{ top: 50%;left: 50%;transform: translate(50%,-75%);}
              .letter-11 {top: 50%;left: 50%;transform: translate(-200%,-25%);}
              .letter-12{ top: 50%;left: 50%;transform: translate(-100%,-25%);}
              .letter-13 {top: 50%;left: 50%;transform: translate(-0%,-25%);}
              .letter-14 {top: 50%;left: 50%;transform: translate(100%,-25%);}
              .letter-15 {top: 50%;left: 50%;transform: translate(-150%,25%);}
              .letter-16 {top: 50%;left: 50%;transform: translate(-50%,25%);}
              .letter-17 {top: 50%;left: 50%;transform: translate(50%,25%);}
            }

          }
          .controls{padding:0;border-bottom: 1px solid rgba(#707070,.44);position: relative;
            input[type="text"]{width: 100%;height: 50px;font-size: 25px;text-align: center;margin: 0;border:none;color:#4D4D4D;border-radius: 0;-webkit-appearance:none;outline: none;}
            .unscramble-btn{width: 50px;height: 50px;font-size: 40px;background: #fff;color: #4D4D4D;font-weight: 500;border:none;text-align: center;cursor: pointer;position: absolute;right:0;top:50%;transform:translateY(-50%);
              &:hover,&:focus{color: #0092BC;}
            }
          }
        }
      }

      .note{font-size: 19px !important;font-weight:400;color: #4D4D4D;position: absolute;bottom:0;}
      ::v-deep(.note small) {
        font-size: 16px;
        margin: 0;
      }
      ::v-deep(.note small + p) {
        margin-bottom: 0;
        margin-top:10px;
      }
    }
  }
}
.buttons {position: absolute;left: 0;bottom: 0;width: 100%;height: 75px;font-size: 30px;cursor: pointer;
  .card-button {width: 100%;height: 100%;font-size:35px;background: #0092BC;color: #fff;font-weight: 700;border:2px solid #fff;border-radius:35px;text-align: center;cursor: pointer;
    &:disabled {background: #C1C6C8;cursor: not-allowed;}
    svg{vertical-align: middle;}
  }
}
</style>

