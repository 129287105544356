<template>
  <div :class="[{ disabled: gameStarted }]" id="startGame">
    <div class="startGameWrapper">
      <div class="welcome" v-show="isWelcome">
        <div class="innerContent">
          <img width="628" src="/images/wegovy-novo-logo.svg" alt="Wegovy Way | Novo Nordisk" style="padding-bottom:30px" />
          <object ref="welcomeAnimation" data="/images/wegovy-way-intro-graphic.svg" type="image/svg+xml"></object>
          <h1>Welcome to</h1>
          <h2>Wegovy<sup>&reg;</sup> Way</h2>
          <p>Test your knowledge on Wegovy<sup>&reg;</sup> and get to the finish line!</p>
          <button class="btn" @click="showHowToPlay1">How to Play <font-awesome-icon :icon="'angle-right'" /></button>
        </div>
      </div>
      <div class="howToPlay" v-show="isHowToPlay1">
        <div class="innerContent">
          <div class="logo">
            <img width="628" src="/images/wegovy-novo-logo.svg" alt="Wegovy Way | Novo Nordisk" style="padding-bottom:30px" />
          </div>
          <button class="back" @click="backToWelcome"><font-awesome-icon :icon="'arrow-left'"></font-awesome-icon></button>
          <h2>How to Play</h2>
          <div class="videoContent">
            <div style="position: relative; padding-top: 56.25%;">
              <iframe
                  src="https://customer-ama66exfn1fajl1o.cloudflarestream.com/5bd1094e9ee930c52e1754ea7ccf6b72/iframe?muted=true&preload=true&loop=true&autoplay=true&poster=https%3A%2F%2Fcustomer-ama66exfn1fajl1o.cloudflarestream.com%2F5bd1094e9ee930c52e1754ea7ccf6b72%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600&controls=false"
                  loading="lazy"
                  style="border: none; position: absolute; top: 0; left: 0; height: 100%; width: 100%;"
                  allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                  allowfullscreen="true"
              ></iframe>
            </div>
          </div>
          <div class="listItems">
            <ul>
              <li>Click or tap on the dice icon</li>
              <li>Your avatar will move across the colored path</li>
            </ul>
          </div>
          <button class="btn" @click="showHowToPlay2">Next <font-awesome-icon :icon="'angle-right'" /></button>
        </div>
      </div>
      <div class="howToPlay" v-show="isHowToPlay2">
        <div class="innerContent">
          <div class="logo">
            <img width="628" src="/images/wegovy-novo-logo.svg" alt="Wegovy Way | Novo Nordisk" style="padding-bottom:30px" />
          </div>
          <button class="back" @click="showHowToPlay1"><font-awesome-icon :icon="'arrow-left'"></font-awesome-icon></button>
          <h2>How to Play</h2>
          <div class="videoContent">
            <div style="position: relative; padding-top: 56.25%;">
              <iframe
                  src="https://customer-ama66exfn1fajl1o.cloudflarestream.com/b4e270f8a06e4568bf5156e25e4eaef6/iframe?muted=true&preload=true&loop=true&autoplay=true&poster=https%3A%2F%2Fcustomer-ama66exfn1fajl1o.cloudflarestream.com%2Fb4e270f8a06e4568bf5156e25e4eaef6%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600&controls=false"
                  loading="lazy"
                  style="border: none; position: absolute; top: 0; left: 0; height: 100%; width: 100%;"
                  allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                  allowfullscreen="true"
              ></iframe>
            </div>
          </div>
          <div class="listItems">
            <ul>
              <li>Land on a colored square to answer a question</li>
              <li>Answer correctly within the allotted time to earn points</li>
            </ul>
          </div>
          <button class="btn" @click="startGame">Let's Begin! <font-awesome-icon :icon="'angle-right'" /></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, computed, ref, nextTick } from 'vue';
import { useStore } from 'vuex';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  components: { FontAwesomeIcon },
  emits: ['startGame'],
  setup(props, { emit }) {
    const welcomeAnimation = ref(null);
    const store = useStore();
    const ctaEnabled = computed(() => store.getters.getCtaEnabled);
    const isWelcome = computed(() => store.getters.getWelcome);
    const isHowToPlay1 = computed(() => store.getters.getHowToPlay1);
    const isHowToPlay2 = computed(() => store.getters.getHowToPlay2);
    const gameStarted = computed(() => store.getters.getGameStarted);
    const showHowToPlay1 = () => {
      store.dispatch('setWelcome', false);
      store.dispatch('setHowToPlay1', true);
      store.dispatch('setHowToPlay2', false);
    };

    const showHowToPlay2 = () => {
      store.dispatch('setWelcome', false);
      store.dispatch('setHowToPlay1', false);
      store.dispatch('setHowToPlay2', true);
    };

    const backToWelcome = () => {
      store.dispatch('setWelcome', true);
      store.dispatch('setHowToPlay1', false);
      store.dispatch('setHowToPlay2', false);
    };

    const startGame = () => {
      emit('startGame');
      setTimeout(() => {
        window.removeEventListener('resize', resizeWelcome);
        store.dispatch('setWelcome', false);
        store.dispatch('setHowToPlay1', false);
        store.dispatch('setHowToPlay2', false);
        store.dispatch('setWelcomeComplete', true);
      }, 2000);
    };

    const resizeWelcome = () => {
      const startWrapper = document.querySelector('.startGameWrapper');
      if (!startWrapper) return;

      const startWrapperWidth = startWrapper.offsetWidth;
      const startWrapperHeight = startWrapper.offsetHeight;
      const aspectRatio = startWrapperWidth / startWrapperHeight;
      let scale = 1;

      if (startWrapperWidth < 750 && startWrapperHeight <= 1125) {
        scale = aspectRatio >= 750 / 1125 ? startWrapperHeight / 1125 : startWrapperWidth / 750;
      } else {
        scale = Math.min(startWrapperWidth / 750, startWrapperHeight / 1125);
      }
      startWrapper.style.transform = `scale(${scale})`;
    };

    const handleObjectLoad = () => {
      const element = welcomeAnimation.value.contentDocument.documentElement;
      const player = element ? element.svgatorPlayer : {};
      if (player && player.play) {
        setTimeout(() => {
          player.play();
        }, 1500);
      }
    };

    onMounted(() => {
      resizeWelcome();
      nextTick(() => {
        const objectElement = welcomeAnimation.value;
        if (objectElement) {
          objectElement.addEventListener('load', handleObjectLoad);
        }
      });
      window.addEventListener('resize', resizeWelcome);
    });

    return {
      isWelcome,
      isHowToPlay1,
      isHowToPlay2,
      gameStarted,
      showHowToPlay1,
      showHowToPlay2,
      backToWelcome,
      startGame,
      resizeWelcome,
      welcomeAnimation,
      ctaEnabled
    };
  }
};
</script>

<style scoped lang="scss">
#startGame {position: absolute;top: 0;left: 0;width: 100%;height: 100vh;height: 100dvh;opacity: 1;z-index: 0;transition: opacity 2s ease-in-out 0s, z-index 0s ease-in-out 2s;background: #D4E5EF radial-gradient(circle, #fff 0%, #D4E5EF 70%, #D4E5EF 100%);display: flex;justify-content: center;
  &.disabled {opacity: 0;z-index: -3;}
  .startGameWrapper {max-width: 750px;width: 100%;display: flex;align-items: center;justify-content: center;text-align: center;transform-origin: center center;
    .welcome {
      h1 {font-size: 50px;color: #3F8CBA;margin: 0;padding: 0;}
      h2 {font-size: 90px;color: #B42E7D;margin: 0;padding: 0;}
      picture {display: block;padding-top: 30px;padding-bottom: 50px;}
      p {font-size: 35px;color: #4D4D4D;font-weight: 700;margin: 0 auto;max-width: 628px;padding-bottom: 30px;}
      button.btn {font-size: 40px;color: #fff;background-color: #B42E7D;border: 1px solid #fff;height: 132px;width: 373px;border-radius: 66px;cursor: pointer;box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);-webkit-appearance: none;appearance: none;
        svg {vertical-align: middle;}
        &:hover {background-color: #3F8CBA;}
      }
    }
    .howToPlay {
      h2 {font-size: 50px;color: #3F8CBA;margin: 0;padding: 0;line-height: 60px;padding-right: 45px;}
      button.back {font-size: 50px;float: left;padding: 0;width: 45px;height: 60px;border: none;color: #3F8CBA;background: transparent;cursor: pointer;
        &:hover, &:active {color: #4D4D4D;}
      }
      .logo{margin: 0 auto 20px;}
      .videoContent {width: 694px;border: 1px solid #707070;background: rgba(255, 255, 255, 0.34);margin: 20px auto;position: relative;}
      .listItems {margin: 0 auto;max-width: 628px;padding-bottom: 40px;text-align: left;
        ul {margin: 0;
          li {font-size: 35px;color: #4D4D4D;font-weight: 700;margin: 0;padding: 0;}
        }
      }
      button.btn {font-size: 40px;color: #fff;background-color: #B42E7D;border: 1px solid #fff;height: 132px;width: 373px;border-radius: 66px;cursor: pointer;box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);-webkit-appearance: none;appearance: none;
        svg {vertical-align: middle;}
        &:hover {background-color: #3F8CBA;}
      }
    }
  }
  @media screen and (max-aspect-ratio: 1/1) {
    .startGameWrapper {height: calc(100% - min(16vw, 120px));}
  }
  @media screen and (min-aspect-ratio: 1/1) {
    .startGameWrapper {height: calc(100% - min(16vh, 120px));}
  }
}
</style>
