<template>
  <div class="review">
    <div class="content">
      <h3>Additional Review Information</h3>
      <div class="reviewContent" ref="reviewBow" @scroll.stop="handleScroll">
        <div v-html="activityContent.AdditionalInfo"></div>
        <div class="references" v-if="activityContent.References">
          <h3>Reference(s)</h3>
          <div v-html="activityContent.References"></div>
        </div>
      </div>
      <span class="scrollIndicator" v-if="!questionData.seenReview">Scroll <font-awesome-icon :icon="'angle-down'" /></span>
    </div>
  </div>
</template>
<script>

import {ref, onMounted, nextTick} from "vue";

export default {
  name: 'ReviewComponent',
  emits: ['seenReview'],
  props: {
    activityContent: {
      type: Object,
      required: true
    },
    questionData: {
      type: Object,
      required: true
    }
  },
  setup(props, { emit }) {
    const reviewBow = ref(null);
    const handleScroll = (e) => {
      const bottom = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 1;
      if (bottom) {
        emit('seenReview');
      }
    };
    onMounted(() => {
      nextTick(() => {
        if(reviewBow.value.scrollHeight - reviewBow.value.scrollTop <= reviewBow.value.clientHeight+20){
          emit('seenReview');
        }
      });

    });
    return {
      handleScroll,
      reviewBow
    };
  }
}
</script>
<style lang="scss">
.review{position: relative;height:100%;display: flex;align-items: center;justify-content: start;text-align: left;width:100%;
  .content{position: relative;z-index: 1;width:100%;font-size:24px;

    h3{font-size: 30px;font-weight: 700;color:#0092BC;margin:0;}
    .scrollIndicator{display: block;text-align: center;color:#C6007E;position: absolute;width:100%;font-weight:700;}
    .reviewContent{height:590px;overflow-y: scroll;padding-bottom:50px;padding-right:12px;text-align: left;box-sizing: border-box;
      h3{font-size: 30px;font-weight: 700;color:#0092BC;margin:0;}
      &::after{content:'';position:absolute;bottom:0;left:0;width:100%;height:50px;background:linear-gradient(to bottom,rgba(255,255,255,0),#fff);}
      .references{font-size:18px;}
    }

  }
}
</style>